import * as React from "react"
import { graphql } from 'gatsby'
import Styled from 'styled-components/macro';
import Image from 'gatsby-image';

import Layout from "../components/layout"
import Meta from '../components/meta'
import Container from '../components/container'
import Headline from '../components/headline'
import Description from '../components/description'
import Button from '../components/button'
import Content from '../components/content'
import ContentRow from '../components/contentRow'

const HeadlineExtended = Styled(Headline)`
  font-size: 5rem;
  line-height: 6rem;
`;

const ContentExtended = Styled(Content)`
  position: relative;
  z-index: 2;
`;

const Hero = Styled.div`
  position: relative;
`;

const Text = Styled.div`
  max-width: 50rem;
  padding: 9rem 0;
`;

const ImageExtended = Styled(Image)`
  height: 100%;
`;

const ImageContainer = Styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  max-height: 900px;
  width: 50%;
  z-index: 1;

  @media (max-width: 1200px) {
    width: 100%;
    opacity: 0.25;
  }
`;

const NavButtonLink = Styled.a`
  cursor: pointer;
  display: block;
  margin-top: 3rem;
`;

const ExtendedButton = Styled(Button)`
`;

const PHTitle = Styled.p`
  font-size: 2rem;
  font-weight: 600;
  margin: 0 2rem 0 0;

  @media (max-width: 600px) {
    margin: 0;
  }
`;

const PHRow = Styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(props) => (props.theme.colors.primary)};
  padding: 2rem 0 1.2rem 0;
  color: ${(props) => (props.theme.colors.black)};

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const Home = ({ data }) => (
  <Layout green>
    <Meta
      title="The Best Design Software"
      description="The best design software for your use case."
      url="/"
    />

    <PHRow>
        <PHTitle>Live on Product Hunt</PHTitle>
        <a href="https://www.producthunt.com/posts/the-ultimate-design-tool-picker?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-the-ultimate-design-tool-picker" target="_blank">
          <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=298998&theme=dark" alt="The Ultimate Design Tool Picker - Find the best design tool for your use case | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" />
        </a>
    </PHRow>

    <Container>
      <Hero>
        <ContentExtended>
            <Text>
                <HeadlineExtended>The Best Design Software For You</HeadlineExtended>
                <Description>There are too many design tools to choose from!
                  Find out which one best fits your needs with this ultimate design tool picker.
                </Description>

                <NavButtonLink href="/app/">
                  <ExtendedButton>
                    Let's go
                  </ExtendedButton>
                </NavButtonLink>
            </Text>
        </ContentExtended>
        <ImageContainer>
          <ImageExtended fluid={'fluid' in data.bannerImageA.childImageSharp ? data.bannerImageA.childImageSharp.fluid : null} alt={"design tools logos"} />
        </ImageContainer>
      </Hero>
    </Container>
    
    <ContentRow
        colorFlag="green"
        headline="The Best Browser Design Tools"
        description="Discover the best design tools that run entirely on your web browser. No installation required!"
        internalLink="/highlight/"
    />
    
  </Layout>
)

export default Home;

export const query = graphql`
  query {
  
    bannerImageA: file(relativePath: { eq: "design-tools.png" }) {
      childImageSharp {
        fluid(maxWidth: 950, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    
  }
`;


