import * as React from "react"
import Styled from 'styled-components/macro';
import Image from 'gatsby-image';

import Headline from './headline'
import Description from './description'
import Content from './content'
import Container from './container'
import Button from './button'


const HeadlineExtended = Styled.h3`
    font-weight: 600;
    color: inherit;
    letter-spacing: -0.3px;
    margin: 0 0 1.5rem 0;
    font-size: 3rem;
    line-height: 4rem;
`;

const ContainerExtended = Styled(Container)`
    padding: 120px 0;
`;

const Text = Styled.div`
  max-width: 40rem;
  margin-right: 6rem;
`;

const Row = Styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px) {
        flex-direction: column;
    }
`;

const ScreenshotContainer = Styled.div`
    height: 100%;
    width: 100%;

    @media (max-width: 900px) {
        margin-top: 6rem;
        width: 90%;
    }
`;

const NavButtonLink = Styled.a`
  cursor: pointer;
  display: block;
  margin-top: 3rem;
`;

const ExtendedButton = Styled(Button)`
`;

const TitleExtended = Styled(Headline)`
  font-size: 4rem;
  line-height: 5rem;
`;

const Hero = Styled.div`
    max-width: 75%;
    margin: auto;
    text-align: center;
`;

const AngleDown = Styled.span`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: transparent;
    text-indent: -9999px;
    border-top: 2px solid ${(props) => props.theme.colors.black};
    border-left: 2px solid ${(props) => props.theme.colors.black};
    text-decoration: none;
    color: transparent;
    transform: rotate(-135deg);
    margin-top: 2rem;
`;

export default ({colorFlag, headline, description, link, image, imagAlt, internalLink}) => (
    <ContainerExtended purple={colorFlag == "purple" ? true : false} green={colorFlag == "green" ? true : false}>
        <Content>
                { image && (
                    <Row>
                        <Text>
                            <HeadlineExtended>{headline}</HeadlineExtended>
                            <Description>
                                {description}
                            </Description>

                            <NavButtonLink href={link} target="_blank">
                                <ExtendedButton green={colorFlag == "green" ? true : false}>
                                    Get it
                                </ExtendedButton>
                            </NavButtonLink>
                        </Text>
                        <ScreenshotContainer>
                            <Image fluid={image} alt={imagAlt} />
                        </ScreenshotContainer>
                    </Row>
                )}
                 { !image && (
                     <Row>
                        <Hero>
                            <TitleExtended>{headline}</TitleExtended>
                            <Description>
                                {description}
                            </Description>

                            { internalLink ?
                                <NavButtonLink href={internalLink}>
                                    <ExtendedButton green={colorFlag == "green" ? true : false}>
                                        Read more
                                    </ExtendedButton>
                                </NavButtonLink>
                                :
                                <AngleDown/>
                            }
                            
                        </Hero>
                    </Row>
                )}
        </Content>
    </ContainerExtended>
)